import React, { useState } from 'react';

const Faq = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const faqData = [
    {
      question: "Who is RevBull? >",
      answer: "We're a fantastic team of InsurTech experts on a mission to offer exceptional value to both consumers and clients. Our goal? To be your go-to destination for top-quality prospects in the property and casualty insurance world.",
    },
    {
      question: "How does RevBull find their prospects? >",
      answer: "We focus our efforts on Paid Search and SEO, honing in on the most promising keywords to generate top-notch prospect data available via our real-time marketplace.",
    },
    {
      question: "How is RevBull different from lead providers? >",
      answer: "100% of our traffic comes directly from our website and marketing channels. We believe in complete transparency, giving you access to detailed reports and performance metrics. That way, you know exactly how your campaigns are doing and where your budget is going.",
    },
    {
      question: "What results can I expect? >",
      answer: "We use our powerful data skills to deliver outstanding outcomes. We aim for contact and bind rates that surpass industry averages, all while keeping your costs in check.",
    },
    {
      question: "How much does it cost? >",
      answer: "You're in control. You only pay for the opportunities and prospect data you receive. When you sign up, we'll ask for a deposit on your credit or debit card, and we'll automatically refill your account when needed.",
    },
  ];

  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  return (
    <section id="about" className="about-ar section-padding" style={{ paddingTop: '5vw' }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 valign" style={{ display: 'flex' }}>
            <div className="content">
              <h6 className="sub-title2 ls10 text-u">FREQUENTLY ASKED QUESTIONS</h6>
              <div className="accordion">
                {faqData.map((item, index) => (
                  <div key={index}>
                    <div
                      className={`question ${activeQuestion === index ? 'active' : ''}`}
                      onClick={() => toggleQuestion(index)}
                    >
                      {item.question}
                    </div>
                    <div className={`answer ${activeQuestion === index ? 'active' : ''}`}>
                      {item.answer}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;